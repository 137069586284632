/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
import { StringTableCommon } from './common/StringTableCommon'
import { StringTableCatalog } from './common/components/catalog/StringTableCatalog'
import { StringTableBlog } from './common/components/blog/StringTableBlog'
export const StringTable = [
  ...StringTableCommon,
  ...StringTableCatalog,
  ...StringTableBlog,
  {
    "key": "sampleStrKey",
    "en": "Sample"
  },
  {
    "key": "orgs",
    "en": "Tenants"
  },
  {
    "key": "ctaButtonLabel",
    "en": "Manage"
  },
  {
    "key": "settingsMaster",
    "en": "Master Settings"
  },
  {
    "key": "welcome",
    "en": "Login"
  },
  {
    "key": "email",
    "en": "Admin/Staff Email"
  },
  {
    "key": "terms&Conditions",
    "en": "Terms & conditions"
  },
  {
    "key": "knowAbout",
    "en": "Know more about"
  },
  {
    "key": "knowAboutSlixta",
    "en": "Know more about Slixta"
  },
  {
    "key": "adminBannerTitle",
    "en": "Slixta"
  },
  {
    "key": "adminBannerDesc",
    "en": "Unified content, commerce, and data platform that helps brands offer better digital experiences for the online audience"
  },
  {
    "key": "getStarted",
    "en": "Get started"
  },
  {
    "key": "statsInfo",
    "en": "Stats info"
  },
  {
    "key": "quickLinks",
    "en": "Quick links"
  },
  {
    "key": "adminBannerAlt",
    "en": "Banner for  dashboard"
  },
  {
    "key": "statsIconsAlt",
    "en": "Icon for stats"
  },
  {
    "key": "contactBannerTitle",
    "en": "Need help?"
  },
  {
    "key": "handClickImgAlt",
    "en": "Contact us"
  },
  {
    "key": "loadingText",
    "en": "Loading..."
  },
  {
    "key": "cbdeffields",
    "en": "Fields"
  },
  {
    "key": "emailcbdeffields",
    "en": "Email Fields"
  },
  {
    "key": "cbdefs",
    "en": "Custom Defined Widgets"
  },
  {
    "key": "emailcbdefs",
    "en": "Email Widgets"
  },
  {
    "key": "cbdats",
    "en": "Data for Widget"
  },
  {
    "key": "contactUsForSupport",
    "en": "Contact us for support"
  },
  {
    "key": "pagecbs",
    "en": "Page Widgets"
  },
  {
    "key": "emailpagecbs",
    "en": "Email Page Widgets"
  },
  {
    "key": "cotypeblocks",
    "en": "Subpage Widgets"
  },
  {
    "key": "cborgs",
    "en": "Reusable Widgets"
  },
  {
    "key": "globalBlocks",
    "en": "Widget Lib"
  },
  {
    "key": "globalBlocksTags",
    "en": "Widget Tags"
  },
  {
    "key": "emailglobalBlocks",
    "en": "Email Widget Lib"
  },
  {
    "key": "blocks",
    "en": "Widgets"
  },
  {
    "key": "blocks-widgets",
    "en": "Widgets"
  },
  {
    "key": "cotypeblocks-widgets",
    "en": "Subpage Widgets"
  },
  {
    "key": "assets",
    "en": "Assets"
  },
  {
    "key": "pages",
    "en": "Pages"
  },
  {
    "key": "site",
    "en": "Site"
  },
  {
    "key": "emailpages",
    "en": "Email Templates"
  },
  {
    "key": "menus",
    "en": "Header"
  },
  {
    "key": "menusl2",
    "en": "Menu Level 2"
  },
  {
    "key": "menusl3",
    "en": "Menu Level 3"
  },
  {
    "key": "footernav",
    "en": "Footer"
  },
  {
    "key": "footernavl2",
    "en": "Sub Nav"
  },
  {
    "key": "orgSwitcher",
    "en": "Org switcher"
  },
  {
    "key": "selectOrg",
    "en": "Select org"
  },
  {
    "key": "uploadFile",
    "en": "Drag & Drop a file here or"
  },
  {
    "key": "blockSelection",
    "en": "Select a widget"
  },
  {
    "key": "emailblockSelection",
    "en": "Select an email widget"
  },
  {
    "key": "ecom",
    "en": "Commerce"
  },
  {
    "key": "settingsbranding",
    "en": "Branding"
  },
  {
    "key": "nav",
    "en": "Navigation"
  },
  {
    "key": "editOrDelete",
    "en": "Edit/Delete"
  },
  {
    "key": "uploadHotspotImage",
    "en": "Upload the image on which you want to add hotspots"
  },
  {
    "key": "bannerImageAlt",
    "en": "Banner Image"
  },
  {
    "key": "cotypes",
    "en": "Custom Objects"
  },
  {
    "key": "cos",
    "en": "Data"
  },
  {
    "key": "cosl2",
    "en": "Nested Data"
  },
  {
    "key": "content",
    "en": "Content",
  },
  {
    "key": "content-types-short",
    "en": "Types",
  },
  {
    "key": "content-types",
    "en": "Content Types",
  },
  {
    "key": "content-fragments",
    "en": "Content Fragments",
  },
  {
    "key": "content-personas-short",
    "en": "Personas",
  },
  {
    "key": "content-personas",
    "en": "User Personas",
  },
  {
    "key": "content-inputs-short",
    "en": "Inputs",
  },
  {
    "key": "content-inputs",
    "en": "Content Inputs",
  },
  {
    "key": "content-outputs-short",
    "en": "Outputs",
  },
  {
    "key": "content-outputs",
    "en": "Content Outputs",
  },
  {
    "key": "content-generator-short",
    "en": "Generator",
  },
  {
    "key": "content-generator",
    "en": "Content Generator",
  },
  {
    "key": "content-cluster-blogs",
    "en": "Content Cluster Blogs",
  },
  {
    "key": "content-blog-generator",
    "en": "Blog Generator",
  },
  {
    "key": "content-planner",
    "en": "Content Planner",
  },
  {
    "key": "content-cluster-titles",
    "en": "Cluster Titles",
  },
  {
    "key": "ai-content",
    "en": "AI Content",
  },
  {
    "key": "campaigns",
    "en": "Campaigns",
  },
  {
    "key": "campaignresult",
    "en": "Results",
  },
  {
    "key": "journeys",
    "en": "Journeys",
  },
  {
    "key": "journeyresult",
    "en": "Results",
  },
  {
    "key": "crm",
    "en": "CRM",
  },
  {
    "key": "crm-contacts",
    "en": "Contacts",
  },
  {
    "key": "crm-contacts-legacy",
    "en": "Contacts [legacy]",
  },
  {
    "key": "crm-contact",
    "en": "Contact",
  },
  {
    "key": "emailpageslib",
    "en": "Email Templates",
  },
  {
    "key": "segments",
    "en": "Segments",
  },
  {
    "key": "cdp-settings",
    "en": "CDP Settings",
  },
  {
    "key": "crm-settings",
    "en": "Settings",
  },
  {
    "key": "crm-inboxes",
    "en": "Inboxes",
  },
  {
    "key": "crm-user-settings",
    "en": "User Settings",
  },
  {
    "key": "crm-email-templates",
    "en": "CRM Email Templates",
  },
  {
    "key": "crm-email-templates-short",
    "en": "Email Templates",
  },
  {
    "key": "crm-sequences",
    "en": "Sequences",
  },
  {
    "key": "crm-sequence-steps",
    "en": "Steps",
  },
  {
    "key": "crm-sequence-enrolments",
    "en": "Enrolments",
  },
  {
    "key": "embed-tags",
    "en": "Embed Tags",
  },
  {
    "key": "ooh",
    "en": "OOH",
  },
  {
    "key": "broadcasts",
    "en": "Broadcasts",
  },
  {
    "key": "transactional",
    "en": "Transactional",
  },
  {
    "key": "notifications",
    "en": "Notifications",
  },
  {
    "key": "popups",
    "en": "Popups",
  },
  {
    "key": "popup-analytics",
    "en": "Popup Analytics",
  },
  {
    "key": "jobs",
    "en": "Jobs",
  },
]
